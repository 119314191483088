// NOTE: `@if true` and separate `:root` blocks are only used to provide code folding in the editor.
// stylelint-disable no-duplicate-selectors

@use "~@webbloqs/design/lib/global/global";
@use "~@webbloqs/design/lib/size/size";
@use "~@webbloqs/design/lib/media/media";
@use "~@webbloqs/design/lib/space/space";
@use "~@webbloqs/design/lib/color/color";
@use "~@webbloqs/design/lib/typography/typography";
@use "~@webbloqs/design/lib/title/title";
@use "~@webbloqs/design/lib/link/link";
@use "~@webbloqs/design/lib/button/button";

@font-face {
    font-display: swap;
    font-family: "NeueHaasUnicaW04";
    font-style: normal;
    font-weight: 300;
    src:
        url("../resources/fonts/NeueHaasUnicaW04-Regular.woff2") format("woff2"),
        url("../resources/fonts/NeueHaasUnicaW04-Regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "NeueHaasUnicaW04";
    font-style: normal;
    font-weight: 600;
    src:
        url("../resources/fonts/NeueHaasUnicaW04-Bold.woff2") format("woff2"),
        url("../resources/fonts/NeueHaasUnicaW04-Bold.woff") format("woff");
}

/* ICON FONT */
// TODO: can this be deleted? it is not used in the design system, only in the legacy design
@font-face {
    font-family: icomoon;
    src: url("../resources/fonts/icomoon.woff?pz8gz9") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* viewports */
@include media.viewports(
    (
        "xxs": 0,
        "xs": 320,
        "sm": 600,
        "md": 900,
        "lg": 1200,
        "xl": 1600,
        "xxl": 2580,
    )
);

/** SIZE SCALES */
// FIXME: add sass-variable with base size again, so it can be set to 0 in debug theme!
:root {
    // --wb-ds-size-scale: 1.6; from _global.scss
    // --wb-ds-size-unit-static: 16; unused here!

    // FIXME: not supported yet, it breaks the legacy design which is based on 1rem = 10px
    // font-size: 100%; // reset default to 1rem = 16px

    // ## Dynamic = Static Sizes, because do not redefine the scale factor in viewports
    --wb-ds-size--7: calc(0.0625rem * var(--wb-ds-size-scale)); // 1px
    --wb-ds-size--13: calc(0.125rem * var(--wb-ds-size-scale)); // 2px
    --wb-ds-size--25: calc(0.25rem * var(--wb-ds-size-scale)); // 4px
    --wb-ds-size--50: calc(0.5rem * var(--wb-ds-size-scale)); // 8px
    --wb-ds-size--68: calc(0.675rem * var(--wb-ds-size-scale)); // 10px
    --wb-ds-size--75: calc(0.75rem * var(--wb-ds-size-scale)); // 12px
    --wb-ds-size--88: calc(0.875rem * var(--wb-ds-size-scale)); // 14px
    --wb-ds-size--100: calc(1rem * var(--wb-ds-size-scale)); // 16px BASE SIZE VALUE from _global.scss
    --wb-ds-size--113: calc(1.125rem * var(--wb-ds-size-scale)); // 18px
    --wb-ds-size--125: calc(1.25rem * var(--wb-ds-size-scale)); // 20px
    --wb-ds-size--138: calc(1.375rem * var(--wb-ds-size-scale)); // 22px
    --wb-ds-size--150: calc(1.5rem * var(--wb-ds-size-scale)); // 24px
    --wb-ds-size--175: calc(1.75rem * var(--wb-ds-size-scale)); // 28px
    --wb-ds-size--200: calc(2rem * var(--wb-ds-size-scale)); // 32px
    --wb-ds-size--225: calc(2.25rem * var(--wb-ds-size-scale)); // 36px
    --wb-ds-size--250: calc(2.5rem * var(--wb-ds-size-scale)); // 40px
    --wb-ds-size--275: calc(2.75rem * var(--wb-ds-size-scale)); // 44px
    --wb-ds-size--300: calc(3rem * var(--wb-ds-size-scale)); // 48px
    --wb-ds-size--325: calc(3.25rem * var(--wb-ds-size-scale)); // 52px
    --wb-ds-size--350: calc(3.5rem * var(--wb-ds-size-scale)); // 56px
    --wb-ds-size--375: calc(3.75rem * var(--wb-ds-size-scale)); // 60px
    --wb-ds-size--400: calc(4rem * var(--wb-ds-size-scale)); // 64px
    --wb-ds-size--425: calc(4.25rem * var(--wb-ds-size-scale)); // 64px
    --wb-ds-size--450: calc(4.5rem * var(--wb-ds-size-scale)); // 72px
    --wb-ds-size--475: calc(4.75rem * var(--wb-ds-size-scale)); // 76px
    --wb-ds-size--500: calc(5rem * var(--wb-ds-size-scale)); // 80px
    --wb-ds-size--525: calc(5.25rem * var(--wb-ds-size-scale)); // 84px
    --wb-ds-size--550: calc(5.5rem * var(--wb-ds-size-scale)); // 88px
    --wb-ds-size--575: calc(5.75rem * var(--wb-ds-size-scale)); // 92px
    --wb-ds-size--600: calc(6rem * var(--wb-ds-size-scale)); // 96px
    --wb-ds-size--625: calc(6.25rem * var(--wb-ds-size-scale)); // 100px
    --wb-ds-size--650: calc(6.5rem * var(--wb-ds-size-scale)); // 104px

    /** Space scale */
    // // "Abstände fix" is not implemented as space tokens, used size tokens instead because they are fixed anyway.
    // "Exchange rate" is 1:25, so 1x = --wb-ds-size--25, 2x = --wb-ds-size--50, and so on.

    --wb-ds-space--xs: var(--wb-ds-size--25);
    --wb-ds-space--s: var(--wb-ds-size--50);
    --wb-ds-space--m: var(--wb-ds-size--100); // default
    --wb-ds-space--l: var(--wb-ds-size--125);
    --wb-ds-space--xl: var(--wb-ds-size--200);
    --wb-ds-space--2xl: var(--wb-ds-size--250);
    --wb-ds-space--3xl: var(--wb-ds-size--300);
    --wb-ds-space--4xl: var(--wb-ds-size--350);

    @include media.at-least(sm) {
        --wb-ds-space--3xl: var(--wb-ds-size--350);
        --wb-ds-space--4xl: var(--wb-ds-size--400);
    }
    @include media.at-least(md) {
        --wb-ds-space--l: var(--wb-ds-size--150);
        --wb-ds-space--xl: var(--wb-ds-size--250);
        --wb-ds-space--2xl: var(--wb-ds-size--300);
        --wb-ds-space--3xl: var(--wb-ds-size--400);
        --wb-ds-space--4xl: var(--wb-ds-size--500);
    }
    @include media.at-least(lg) {
        --wb-ds-space--xs: var(--wb-ds-size--50);
        --wb-ds-space--s: var(--wb-ds-size--100);
        --wb-ds-space--m: var(--wb-ds-size--150);
        --wb-ds-space--l: var(--wb-ds-size--200);
        --wb-ds-space--xl: var(--wb-ds-size--300);
        --wb-ds-space--2xl: var(--wb-ds-size--400);
        --wb-ds-space--3xl: var(--wb-ds-size--500);
        --wb-ds-space--4xl: var(--wb-ds-size--550);
    }
    @include media.at-least(xl) {
        --wb-ds-space--l: var(--wb-ds-size--250);
        --wb-ds-space--xl: var(--wb-ds-size--350);
        --wb-ds-space--2xl: var(--wb-ds-size--500);
        --wb-ds-space--3xl: var(--wb-ds-size--550);
        --wb-ds-space--4xl: var(--wb-ds-size--650);
    }
}

/** FONTS/ TYPOGRAPHY */
:root {
    // type styles in Figma: <https://www.figma.com/file/kzkCWRL75ppJpFfn4pYOvJ/ewz-Design-System?type=design&node-id=114-6822&mode=dev>

    // Default Font size for ewz is 1rem = 16px – is defined alongside "sizes", see above.

    /* Font Families */
    --wb-ds-font-family--default: "NeueHaasUnicaW04";
    --wb-ds-font-family--icon: icomoon;

    /* Font Weights */
    --wb-ds-font-weight--default: 300;
    --wb-ds-font-weight--bold: 600;

    /* Font Sizes */
    --wb-ds-font-size-static--xs: var(--wb-ds-size--75);
    --wb-ds-font-size-static--s: var(--wb-ds-size--88);
    --wb-ds-font-size-static--m: var(--wb-ds-size--100);
    --wb-ds-font-size-static--l: var(--wb-ds-size--113);

    --wb-ds-line-height-static--xs: 1.25;
    --wb-ds-line-height-static--s: 1.5;
    --wb-ds-line-height-static--m: 1.5;
    --wb-ds-line-height-static--l: 1.5;

    --wb-ds-font-size--xs: var(--wb-ds-size--75);
    --wb-ds-font-size--s: var(--wb-ds-size--88);
    --wb-ds-font-size--m: var(--wb-ds-size--100);
    --wb-ds-font-size--l: var(--wb-ds-size--113);
    --wb-ds-font-size--xl: var(--wb-ds-size--150);
    --wb-ds-font-size--2xl: var(--wb-ds-size--200);

    --wb-ds-line-height--xs: 1.25;
    --wb-ds-line-height--s: 1.5;
    --wb-ds-line-height--m: 1.5;
    --wb-ds-line-height--l: 1.5;
    --wb-ds-line-height--xl: 1.25;
    --wb-ds-line-height--2xl: 1.25;

    @include media.at-least(md) {
        --wb-ds-font-size--m: var(--wb-ds-size--113);
        --wb-ds-font-size--l: var(--wb-ds-size--138);
        --wb-ds-font-size--xl: var(--wb-ds-size--200);
        --wb-ds-font-size--2xl: var(--wb-ds-size--300);
    }

    @include media.at-least(xl) {
        --wb-ds-font-size--xs: var(--wb-ds-size--88);
        --wb-ds-font-size--s: var(--wb-ds-size--100);
        --wb-ds-font-size--m: var(--wb-ds-size--125);
        --wb-ds-font-size--l: var(--wb-ds-size--150);
        --wb-ds-font-size--xl: var(--wb-ds-size--250);
        --wb-ds-font-size--2xl: var(--wb-ds-size--350);
    }

    /* Titles */
    --wb-ds-title__bezel-top: var(--wb-ds-space--none);
    --wb-ds-title__font-weight: var(--wb-ds-font-weight--bold);

    --wb-ds-title-level-4__font-size: var(--wb-ds-size--113);
    --wb-ds-title-level-3__font-size: var(--wb-ds-size--125);
    --wb-ds-title-level-2__font-size: var(--wb-ds-size--150);
    --wb-ds-title-level-1__font-size: var(--wb-ds-size--200);

    --wb-ds-title-level-4__line-height: 1.25;
    --wb-ds-title-level-3__line-height: 1.25;
    --wb-ds-title-level-2__line-height: 1.25;
    --wb-ds-title-level-1__line-height: 1.25;

    @include media.at-least(md) {
        --wb-ds-title-level-4__font-size: var(--wb-ds-size--125);
        --wb-ds-title-level-3__font-size: var(--wb-ds-size--150);
        --wb-ds-title-level-2__font-size: var(--wb-ds-size--200);
        --wb-ds-title-level-1__font-size: var(--wb-ds-size--300);
    }

    @include media.at-least(xl) {
        --wb-ds-title-level-4__font-size: var(--wb-ds-size--150);
        --wb-ds-title-level-3__font-size: var(--wb-ds-size--175);
        --wb-ds-title-level-2__font-size: var(--wb-ds-size--250);
        --wb-ds-title-level-1__font-size: var(--wb-ds-size--350);
    }
}

// # Brand Colors <https://www.figma.com/file/kzkCWRL75ppJpFfn4pYOvJ/ewz-Design-System?type=design&node-id=2%3A492&mode=design&t=Ardlx8zfqvDRiYwe-1>
:root {
    // ## Accent
    // ### Primary
    --wb-ds-color-blue--10: #eef8ff;
    --wb-ds-color-blue--20: #c2e5ff;
    --wb-ds-color-blue--40: #85ccff;
    --wb-ds-color-blue--60: #47b3ff;
    --wb-ds-color-blue--80: #0a99ff;
    --wb-ds-color-blue--100: #0074c8;
    --wb-ds-color-blue--120: #00477a;
    --wb-ds-color-blue--140: #003763;

    // ### Secondary
    --wb-ds-color-green--10: #f6fbef;
    --wb-ds-color-green--20: #e3f2cf;
    --wb-ds-color-green--40: #daedbf;
    --wb-ds-color-green--60: #bee090;
    --wb-ds-color-green--80: #8ec543;
    --wb-ds-color-green--100: #78b725;
    --wb-ds-color-green--120: #597f24;
    --wb-ds-color-green--140: #005124;

    // ## States
    // ### Success
    --wb-ds-color-success--10: #ebfff4;
    --wb-ds-color-success--20: #c2ffdd;
    --wb-ds-color-success--40: #5cffa5;
    --wb-ds-color-success--60: #1fff84;
    --wb-ds-color-success--80: #00e065;
    --wb-ds-color-success--100: #009844;
    --wb-ds-color-success--120: #00662e;
    --wb-ds-color-success--140: #003d1c;

    // ### Warning
    --wb-ds-color-warning--10: #fffceb;
    --wb-ds-color-warning--20: #fff6c2;
    --wb-ds-color-warning--40: #fff3ad;
    --wb-ds-color-warning--60: #ffea70;
    --wb-ds-color-warning--80: #ffe033;
    --wb-ds-color-warning--100: #f3cd00;
    --wb-ds-color-warning--120: #b89c00;
    --wb-ds-color-warning--140: #7a6800;

    // ### Error
    --wb-ds-color-error--10: #ffebec;
    --wb-ds-color-error--20: #ffc2c5;
    --wb-ds-color-error--40: #ff7077;
    --wb-ds-color-error--60: #ff333d;
    --wb-ds-color-error--80: #f5000c;
    --wb-ds-color-error--100: #d22630;
    --wb-ds-color-error--120: #8f0007;
    --wb-ds-color-error--140: #520004;

    // ### Info
    --wb-ds-color-info--10: #f6f7fa;
    --wb-ds-color-info--20: #e4eaf1;
    --wb-ds-color-info--40: #d4dbe5;
    --wb-ds-color-info--60: #bcc8db;
    --wb-ds-color-info--80: #9caec7;
    --wb-ds-color-info--100: #748aaa;
    --wb-ds-color-info--120: #4d6283;
    --wb-ds-color-info--140: #2c3f5b;

    // ## Grayscale
    // ### Grays
    --wb-ds-color-gray--10: #f4f5f6;
    --wb-ds-color-gray--20: #dee2e3;
    --wb-ds-color-gray--40: #bdc4c7;
    --wb-ds-color-gray--60: #9ca7ab;
    --wb-ds-color-gray--80: #7b898e;
    --wb-ds-color-gray--100: #677479;
    --wb-ds-color-gray--120: #4b5558;
    --wb-ds-color-gray--140: #383f42;

    // ### Black
    --wb-ds-color-black--100: #000000;
    --wb-ds-color-black--80: rgba(0, 0, 0, 0.8);
    --wb-ds-color-black--60: rgba(0, 0, 0, 0.6);
    --wb-ds-color-black--40: rgba(0, 0, 0, 0.4);
    --wb-ds-color-black--20: rgba(0, 0, 0, 0.2);
    --wb-ds-color-black--10: rgba(0, 0, 0, 0.1);
    --wb-ds-color-black--0: rgba(0, 0, 0, 0);

    // ### White
    --wb-ds-color-white--100: #ffffff;
    --wb-ds-color-white--80: rgba(255, 255, 255, 0.8);
    --wb-ds-color-white--60: rgba(255, 255, 255, 0.6);
    --wb-ds-color-white--40: rgba(255, 255, 255, 0.4);
    --wb-ds-color-white--20: rgba(255, 255, 255, 0.2);
    --wb-ds-color-white--10: rgba(255, 255, 255, 0.1);
    --wb-ds-color-white--0: rgba(255, 255, 255, 0);
}

/** COLORS */
:root {
    /* primary colors */
    --wb-ds-color-primary--10: var(--wb-ds-color-blue--10);
    --wb-ds-color-primary--20: var(--wb-ds-color-blue--20);
    --wb-ds-color-primary--40: var(--wb-ds-color-blue--40);
    --wb-ds-color-primary--60: var(--wb-ds-color-blue--60);
    --wb-ds-color-primary--80: var(--wb-ds-color-blue--80);
    --wb-ds-color-primary--100: var(--wb-ds-color-blue--100);
    --wb-ds-color-primary--120: var(--wb-ds-color-blue--120);
    --wb-ds-color-primary--140: var(--wb-ds-color-blue--140);

    // DS aliases:
    --wb-ds-color-primary--lightest: var(--wb-ds-color-primary--10);
    --wb-ds-color-primary--default: var(--wb-ds-color-primary--100);
    --wb-ds-color-primary--darkest: var(--wb-ds-color-primary--140);
    // backwards compatibility aliases:
    --wb-ds-color-primary--x-light: var(--wb-ds-color-primary--10);
    --wb-ds-color-primary--light: var(--wb-ds-color-primary--10);
    --wb-ds-color-primary--dark: var(--wb-ds-color-primary--120);
    --wb-ds-color-primary--x-dark: var(--wb-ds-color-primary--140);

    /* secondary colors */
    --wb-ds-color-secondary--10: var(--wb-ds-color-green--10);
    --wb-ds-color-secondary--20: var(--wb-ds-color-green--20);
    --wb-ds-color-secondary--40: var(--wb-ds-color-green--40);
    --wb-ds-color-secondary--60: var(--wb-ds-color-green--60);
    --wb-ds-color-secondary--80: var(--wb-ds-color-green--80);
    --wb-ds-color-secondary--100: var(--wb-ds-color-green--100);
    --wb-ds-color-secondary--120: var(--wb-ds-color-green--120);
    --wb-ds-color-secondary--140: var(--wb-ds-color-green--140);
    // DS aliases:
    --wb-ds-color-secondary--lightest: var(--wb-ds-color-secondary--10);
    --wb-ds-color-secondary--default: var(--wb-ds-color-secondary--100);
    --wb-ds-color-secondary--darkest: var(--wb-ds-color-secondary--140);

    /* gray colors */
    // DS aliases:
    --wb-ds-color-gray--lightest: var(--wb-ds-color-gray--10);
    --wb-ds-color-gray--default: var(--wb-ds-color-gray--40);
    --wb-ds-color-gray--darkest: var(--wb-ds-color-gray--100);
    --wb-ds-color-gray--white: var(--wb-ds-color-white--100);
    --wb-ds-color-gray--black: var(--wb-ds-color-black--100);
    // backwards compatibility aliases:
    // NOTE those grays are rather light, extra dark is only 80, should be 100 or 120
    --wb-ds-color-gray--x-light: var(--wb-ds-color-gray--10);
    --wb-ds-color-gray--light: var(--wb-ds-color-gray--20);
    --wb-ds-color-gray--dark: var(--wb-ds-color-gray--60);
    --wb-ds-color-gray--x-dark: var(--wb-ds-color-gray--80);

    /* signal colors */
    --wb-ds-color-success--lightest: var(--wb-ds-color-success--10);
    --wb-ds-color-success--default: var(--wb-ds-color-success--100);
    --wb-ds-color-success--darkest: var(--wb-ds-color-success--140);

    --wb-ds-color-error--lightest: var(--wb-ds-color-error--10);
    --wb-ds-color-error--default: var(--wb-ds-color-error--100);
    --wb-ds-color-error--darkest: var(--wb-ds-color-error--140);

    --wb-ds-color-warning--lightest: var(--wb-ds-color-warning--10);
    --wb-ds-color-warning--default: var(--wb-ds-color-warning--100);
    --wb-ds-color-warning--darkest: var(--wb-ds-color-warning--140);

    --wb-ds-color-info--lightest: var(--wb-ds-color-info--10);
    --wb-ds-color-info--default: var(--wb-ds-color-info--100);
    --wb-ds-color-info--darkest: var(--wb-ds-color-info--140);

    /* backwards compatibility aliases for signal colors */
    --wb-ds-color-success--light: var(--wb-ds-color-success--lightest);
    --wb-ds-color-error--light: var(--wb-ds-color-error--lightest);
    --wb-ds-color-info--light: var(--wb-ds-color-info--lightest);

    /* Text state colors */
    --wb-ds-link__color: var(--wb-ds-color-primary--120);
    --wb-ds-button__color: var(--wb-ds-color-primary--120);

    --wb-ds-color-text--default: var(--wb-ds-color-primary--120);
    --wb-ds-color-text--hover: var(--wb-ds-color-primary--120);
    --wb-ds-color-text--focus: var(--wb-ds-color-primary--120);
    --wb-ds-color-text--active: var(--wb-ds-color-primary--120);
    --wb-ds-color-text--visited: var(--wb-ds-color-primary--120);
    --wb-ds-color-text--disabled: var(--wb-ds-color-gray--40);
    --wb-ds-color-text--valid: var(--wb-ds-color-success--100);
    --wb-ds-color-text--invalid: var(--wb-ds-color-error--100);
}

/** ANIMATIONS */
:root {
    --wb-ds-animation-timing-function: ease;

    --wb-ds-animation-duration--fast: 0.25s;
    --wb-ds-animation-duration--medium: 0.3s;
    --wb-ds-animation-duration--slow: 0.55s;
    --wb-ds-animation-duration--default: var(--wb-ds-animation-duration--medium);

    --wb-ds-animation-delay--fast: 0s;
    --wb-ds-animation-delay--medium: 0.2s;
    --wb-ds-animation-delay--slow: 0.5s;
    --wb-ds-animation-delay--default: var(--wb-ds-animation-delay--fast);
}

/** GRID */
:root {
    --wb-ds-grid__max-width: 1080px;
    --wb-ds-grid__grid-margin: calc(var(--wb-ds-space--m) * 1.5);
    --wb-ds-grid__gutter-width: var(--wb-ds-size--100);
    --wb-ds-grid__gutter-width--divided: var(--wb-ds-size--50);

    /* space overwrites */
    --wb-ds-grid__gutter-y: var(--wb-ds-space--l);
}

/** BORDERS */
:root {
    /* alert overwrites */
    --wb-alert__border-width: 0;

    /* Border widths */
    --wb-ds-border-width--2x-thin: var(--wb-ds-size--0);
    --wb-ds-border-width--default: var(--wb-ds-size--7);
    --wb-ds-border-width--thick: var(--wb-ds-size--13);
    --wb-ds-border-width--x-thick: var(--wb-ds-size--25);

    /* Border state widths */
    --wb-ds-border-width--hover: var(--wb-ds-border-width--thick);
    --wb-ds-border-width--focus: var(--wb-ds-border-width--hover);
    --wb-ds-border-width--active: var(--wb-ds-border-width--hover);

    /* Border colors */
    --wb-ds-color-border--default: var(--wb-ds-color-gray--40);
    --wb-ds-color-border--hover: var(--wb-ds-color-primary--120);
    --wb-ds-color-border--focus: var(--wb-ds-color-primary--120);
    --wb-ds-color-border--active: var(--wb-ds-color-primary--120);
    --wb-ds-color-border--visited: var(--wb-ds-color-primary--100);
    --wb-ds-color-border--disabled: var(--wb-ds-color-gray--40);
    --wb-ds-color-border--valid: var(--wb-ds-color-success--100);
    --wb-ds-color-border--invalid: var(--wb-ds-color-error--100);

    /* Hairline */
    --wb-ds-color-border--hairline: var(--wb-ds-color-gray--80);
    --wb-ds-border-width--hairline: var(--wb-ds-size--7);
    --wb-ds-border--hairline: var(--wb-ds-border-width--hairline) solid var(--wb-ds-color-border--hairline);

    /** Border Radii */
    --wb-ds-border-radius--small: 0;
    --wb-ds-border-radius--default: var(--wb-ds-size--13);
    --wb-ds-border-radius--medium: var(--wb-ds-size--25);
    --wb-ds-border-radius--large: var(--wb-ds-size--50);
    --wb-ds-border-radius--full: 50%;
}

/** Z-INDICES */
:root {
    --wb-ds-zindex--behind: -1;
    --wb-ds-zindex--content: 0;
    --wb-ds-zindex--element: 5;
    --wb-ds-zindex--tooltip: 10;
    --wb-ds-zindex--behind-backdrop: 50;
    --wb-ds-zindex--backdrop: 100;
    --wb-ds-zindex--backdrop-white: 110;
    --wb-ds-zindex--sticky-behind-flyout: 305;
    --wb-ds-zindex--sticky: 310;
    --wb-ds-zindex--menu: 500;
    --wb-ds-zindex--flyout: 700;
    --wb-ds-zindex--flyout-avatar: 720;
    --wb-ds-zindex--overlay: 900;
}

/* FORMS AND FORM FIELDS */
:root {
    /* FORMS */
    // left-align form controls
    @at-root wb-form wb-layout.wb-form__controls {
        --wb-layout__justify-content: start;
    }

    /* FIELD GROUPS*/
    --wb-field-group__bezel: var(--wb-ds-space--none);
    --wb-field-group-title__bezel: var(--wb-ds-space--none);
    --wb-field-group-title__bezel-bottom: var(--wb-ds-space--default);
    --wb-field-group-title__font-size: var(--wb-ds-title-level-3__font-size);
    --wb-field-group-title__line-height: var(--wb-ds-title-level-3__line-height);
    --wb-field-group-title__font-weight: var(--wb-ds-title__font-weight);

    /* FORM FIELDS */
    // 🧩🧩🧩 ds-field and parts

    // 🧩 field

    // typography
    // defaults are fine :)

    // form field spacing
    // set the layout gutter to 0 because we need different space between above and below the input
    // SASS NOTE: "at-root" does not mean "in :root" but "outside of any sass nesting", so here it is "NOT in :root" 😅
    @at-root wb-dropdown-field,
        wb-text-field,
        wb-upload-field,
        wb-checkbox-field,
        wb-radio-field {
        --wb-layout__gutter-height: var(--wb-ds-size--50);
    }

    @at-root wb-dropdown-field,
        wb-text-field {
        input,
        select,
        textarea {
            &:focus {
                --wb-ds-field__border-width: var(--wb-ds-border-width--thick);
            }
        }
    }

    @at-root wb-checkbox-field,
        wb-radio-field {
        --wb-checkbox-field__outline--active: var(--wb-ds-border-width--thick) solid var(--wb-ds-color-primary--100);

        input {
            outline-offset: calc(var(--wb-ds-border-width--thick) * -1);

            &:focus-visible {
                outline: var(--wb-ds-border-width--thick) solid var(--wb-ds-color-primary--100);
                outline-offset: var(--wb-ds-border-width--thick);
            }
        }

        .wb-checkbox-field__layout,
        .wb-radio-field__layout {
            overflow: initial;
        }

        // layout wrapper of options (excluding group label)
        wb-layout {
            --wb-layout__gutter-width: var(--wb-ds-size--200);
            --wb-layout__gutter-height: var(--wb-ds-size--100);

            // layout wrapper of option and label
            wb-field-layout {
                --wb-layout__gutter-width: var(--wb-ds-size--75);
            }
        }
    }

    // 🎨 figma: space between label and input is 2x
    --wb-ds-field-label__bezel-bottom: var(--wb-ds-size--50); /* BUG */
    // 🎨 figma: space between input and validation message is 1x
    --wb-ds-field-message__bezel-top: var(--wb-ds-size--25);
    // FIXME (WEBBLQS-745): remove when webbloqs 3.3.3 is released
    --wb-ds-field-message-is-error__bezel-top: var(--wb-ds-field-message__bezel-top);
    // 🎨 figma: space below validation message (above hint) is 1x
    --wb-ds-field-message__bezel-bottom: var(--wb-ds-size--25);

    // form field input spacing (4x as default because matches most inputs
    --wb-ds-field__bezel: var(--wb-ds-size--100);

    // form field colors
    --wb-ds-field__background: var(--wb-ds-color-gray--white);

    // form field borders
    --wb-ds-field__border-width: var(--wb-ds-size--7);
    --wb-ds-field__border-radius: var(--wb-ds-space--none);
    --wb-ds-field__border-color: var(--wb-ds-color-gray--80);
    --wb-ds-field__border-color--hover: var(--wb-ds-color-primary--120);
    --wb-ds-field__border-color--focus: var(--wb-ds-color-primary--100);
    --wb-ds-field__border-color--active: var(--wb-ds-color-primary--100);
    --wb-ds-field__border-color--invalid: var(--wb-ds-color-border--invalid);

    // 🧩 field-label part
    // NOTE this font-size is viewport specific. Take care when comparing: Figma examples are on a larger viewport than our storybook examples.
    --wb-ds-field-label__font-size: var(--wb-ds-font-size--s);
    --wb-ds-field-label__line-height: var(--wb-ds-line-height--s);
    --wb-ds-field-label__color: var(--wb-ds-color-primary--120);
    // 🎨 figma: space between label text and required marker is 1x
    --wb-ds-field-label-marker__space-row: var(--wb-ds-size--25);

    // 🧩 field-message part
    // validation message have icons
    // NOTE: currently there is no icon, so we hide it below.
    // Currently, to set a custom icon, a property needs to be set per form-field, e.g. validationIconProps='alert-filled'
    --wb-ds-field-message-icon__name: "alert-filled";
    --wb-ds-field-message-icon__display: none; // in current design spec, no icon is displayed
    --wb-ds-field-message__font-size: var(--wb-ds-font-size--s);
    --wb-ds-field-message__line-height: var(--wb-ds-line-height--s);

    // 🇺🇸 disabled state
    // 🎨 figma: label, border, value, message are gray80, background is gray10
    // NOTE setting ds-field token first, then check if design in also consistent in figma. if not change ds-field to text-field.
    --wb-ds-field__opacity--disabled: 1;
    --wb-ds-field__background--disabled: var(--wb-ds-color-gray--10);
    --wb-ds-field__border-color--disabled: var(--wb-ds-color-gray--80);
    // NOTE the following is not needed when we can set this default:
    //      `--wb-ds-color-text--disabled: var(--wb-ds-color-gray--80);` (seems sensible, need to check rest of design)
    --wb-ds-field__color--disabled: var(--wb-ds-color-gray--80);
    --wb-ds-field-label__color--disabled: var(--wb-ds-color-gray--80);

    // 🇺🇸 invalid state
    // 🎨 figma: label, border, value, message are red
    // NOTE: in figma also the font-size for value is larger, seems to be unintended (wont' do)
    --wb-ds-field__color--invalid: var(--wb-ds-color-text--invalid);
    --wb-ds-field-label__color--invalid: var(--wb-ds-color-text--invalid);

    // 🗳️🗳️🗳️ element specific

    // 🗳️ text-field element
    // just the ds-field defaults are almost enough, we only need to set the fixed height for the input
    --wb-text-field__input-height: var(--wb-ds-size--350);
    --wb-text-field-is-textarea__min-height: var(--wb-ds-size--650);
    --wb-text-field-placeholder__color: var(--wb-ds-color-gray--100);

    // 🗳️ dropdown-field element
    // * only supports native appearance, meaning we only style the "closed" state to spec
    // 🎨 figma: has same fixed height like text-field
    --wb-dropdown-field__input-height: var(--wb-text-field__input-height);
    // dropdown-field has a fixed height, so we need to specify the line-height accordingly to always center the text
    --wb-dropdown-field__line-height: var(--wb-ds-size--150);
    // 🎨 figma: has a slightly larger icon (than the webbloqs default)
    // NOTE: uses the wb default chevron icon which is close enough, sizing might change once the real SVG is used
    --wb-dropdown-field-icon__size: var(--wb-ds-size--138);

    // 🗳️ radio-field element
    // What is what: A Radio Field has a Label, an Options Group, and a Message.
    //               The Options Group consists of <wb-option> elements.
    //               Each Option has a Value and a Label.
    //               Each Value has an Icon in inside (a circle when checked, none when not checked).

    // typography
    // 🎨 figma: while the field-label is size S, the option-labels are size M
    --wb-option-label__font-size: var(--wb-ds-font-size--m);
    --wb-option-label__line-height: var(--wb-ds-line-height--m);

    // dimensions
    // 🎨 figma: vertical space between label and options-group is 2x
    // (default for ds-field-label already correct)

    // 🎨 figma: vertical space below options-group is 6x (i.e., the bezel-bottom of the whole field is 6x)
    --wb-radio-field__bezel-bottom: var(--wb-ds-size--150);
    // 🎨 figma: with vertical direction, vertical space between options is 4x
    --wb-radio-field-gutter-height: var(--wb-ds-size--25);

    @at-root wb-checkbox-field {
        --wb-checkbox-field__background--active: var(--wb-ds-color-primary--100);
        --wb-ds-field__color: var(--wb-ds-color-gray--white);
        --wb-ds-field__border-radius: var(--wb-ds-border-radius--default);
        --wb-checkbox-field-icon__left: 9px;
        --wb-checkbox-field-icon__right: 7px;

        .wb-checkbox-field__option:hover {
            --wb-checkbox-field__background--active: var(--wb-ds-color-primary--120);
            --wb-checkbox-border--valid: var(--wb-ds-color-primary--100);
        }
    }

    @at-root wb-radio-field,
        wb-checkbox-field {
        --wb-ds-field__border-color: var(--wb-ds-color-primary--100);
        // FIXME (#WEBBLQS-727): need wb-option token support to solve this more elegantly
        --wb-ds-field__box-shadow: inset 0px var(--wb-ds-field__border-width) 0 0 var(--wb-ds-field__border-color),
            var(--wb-text-field__box-shadow-right, inset calc(var(--wb-ds-field__border-width) * -1) 0px 0 0 var(--wb-ds-field__border-color)),
            var(--wb-text-field__box-shadow-bottom, inset 0px calc(var(--wb-ds-field__border-width) * -1) 0 0 var(--wb-ds-field__border-color)),
            var(--wb-text-field__box-shadow-left, inset var(--wb-ds-field__border-width) 0px 0 0 var(--wb-ds-field__border-color));
        --wb-ds-field__box-shadow--hover: inset 0px var(--wb-ds-field__border-width) 0 0 var(--wb-ds-color-primary--120),
            var(--wb-text-field__box-shadow-right, inset calc(var(--wb-ds-field__border-width) * -1) 0px 0 0 var(--wb-ds-color-primary--120)),
            var(--wb-text-field__box-shadow-bottom, inset 0px calc(var(--wb-ds-field__border-width) * -1) 0 0 var(--wb-ds-color-primary--120)),
            var(--wb-text-field__box-shadow-left, inset var(--wb-ds-field__border-width) 0px 0 0 var(--wb-ds-color-primary--120));
        // FIXME (#WEBBLQS-727): focus state is not implemented yet
        // would be broken because outline + border + inset box-shadow would be cut off on the left (because of layout negative margin)
        --wb-ds-field__box-shadow--focus: var(--wb-ds-field__box-shadow--hover);

        // 🎨 figma: the size of the value (circle outside) is 24px
        // NOTE should use equivalent size tokens if they exist (but they are not imported into this example)
        --wb-option__size: var(--wb-ds-size--150);
    }

    @at-root wb-radio-field {
        --wb-ds-field__color: var(--wb-ds-color-primary--100);
        // 🎨 figma: the size of the icon (inside the circle) is 20px
        --wb-option-icon__size: var(--wb-ds-size--125);

        .wb-radio-field__option:focus {
            --wb-ds-field__color: var(--wb-ds-color-primary--140);
        }

        .wb-radio-field__option:hover {
            --wb-ds-field__color: var(--wb-ds-color-primary--120);
        }
    }

    // options coloring and borders

    // NOTE: in general, there are no specific tokens for "elements inside other elements",
    //       instead use "parent child" selectors (i.e. nesting in SASS) as shown below.
    //       Counterexample: we do want tokens like `--wb-radio-field__wb-option__border-color--hover--checked`
    //       This is in contrast to DS tokens, where there are tokens for "shared parts" like the "message" in a "form field"),
    //       but neither "form field" nor "message" are actual elements (e.g. there is no `<wb-form-field>`).
    //       Also, we recommend using pseudo selectors because they will be applied in order as written in the theme,
    //       whereas css properties are applied in whichever order they are used in the element style source code!
    @at-root wb-radio-field {
        wb-option {
            // 🎨 figma: the icon (circle inside the radio, when checked) is primary blue
            --wb-option-icon__color: var(--wb-ds-color-primary--100);

            // 🎨 figma: a checked options value has a border of 2px
            --wb-option__border-width--checked: var(--wb-ds-size--13);

            &:hover {
                // 🎨 figma: on hover, the value border is dark blue
                --wb-option__border-color--hover: var(--wb-ds-color-primary--140);

                // 🎨 figma: on hover, the icon (circle inside the value, visible when checked) is dark blue
                --wb-option-icon__color--hover: var(--wb-ds-color-primary--140);
            }

            &:disabled {
                // 🎨 figma: when disabled, the value border is gray60. Important: still gray60 when hovered!
                --wb-option__border-color--disabled: var(--wb-ds-color-gray--60);

                // 🎨 figma: when disabled, the icon (circle inside the value, when checked) is gray60. Important: still gray60 when hovered!
                --wb-option-icon__color--hover: var(--wb-ds-color-gray--60);
            }

            &:focus {
                // NOTE: implements the focus rings!
                //       How? all the borders above are actually using box-shadow (that's the default),
                //       while the focus ring is an actual border *in addition* to the box-shadow-based border.
                //       For this reason the whole border needs to be specified here (not just single props).
                // 🎨 figma: when focused, the option value has a focus ring of 2px in primary blue
                --wb-option__border: var(--wb-ds-size--13) solid var(--wb-ds-color-primary--100);
            }
        }
    }

    @at-root wb-upload-field {
        .wb-upload-field {
            --wb-box__bezel: var(--wb-ds-space--l);
            --wb-box__box-shadow: none;
            --wb-box__border: var(--wb-ds-border-width--thick) dashed var(--wb-ds-color-border--active);

            &--dragging {
                --wb-box__background: var(--wb-ds-color-primary--10);
            }
        }

        .wb-upload-field__wrapper {
            --wb-layout__gutter-height: var(--wb-ds-space--l);
            --wb-layout__flex-direction: column-reverse;
        }

        .wb-upload-field__dropzone {
            --wb-layout__gutter-height: var(--wb-ds-space--s);
        }

        .wb-upload-field__info {
            --wb-icon__size: var(--wb-ds-size--200);
        }

        .wb-upload-field__info-text {
            --wb-upload-field-placeholder__font-size: var(--wb-ds-font-size--l);
            --wb-upload-field-placeholder__line-height: var(--wb-ds-line-height--l);
        }

        .wb-upload-field__info,
        .wb-upload-field__controls {
            --wb-layout__justify-content: center;
        }

        .wb-upload-field__controls {
            --wb-layout__gutter-width: var(--wb-ds-space--none);
            --wb-layout__gutter-height: var(--wb-ds-space--none);
        }

        .wb-upload-field__button {
            --wb-upload-field-button__bezel-top: var(--wb-ds-space--none);
            --wb-upload-field-button__bezel-bottom: var(--wb-ds-space--none);
            --wb-upload-field-button__bezel-left: var(--wb-ds-space--none);
            --wb-upload-field-button__bezel-right: var(--wb-ds-space--none);
            --wb-upload-field-button__color: var(--wb-ds-color-primary--100);
            --wb-upload-field-button__font-weight: var(--wb-ds-font-weight--bold);
        }

        .wb-upload-field__files {
            --wb-stack__space: var(--wb-ds-space--none);
        }

        .wb-upload-field__file {
            --wb-bezel__bezel-left: var(--wb-ds-space--none);
            --wb-bezel__bezel-right: var(--wb-ds-space--none);
            --wb-icon__size: var(--wb-ds-size--100);
            --wb-icon__fill: var(--wb-ds-color-primary--100);

            border-bottom: var(--wb-ds-border-width--default) solid var(--wb-ds-color-border--default);
        }

        .wb-upload-field__file-info {
            --wb-layout__flex-direction: column;
            --wb-layout__gutter-height: var(--wb-ds-space--none);
        }

        .wb-upload-field__file-name {
            --wb-text__color: var(--wb-ds-color-gray--black);
        }

        .wb-upload-field__file-size {
            --wb-text__font-size: var(--wb-ds-font-size--s);
            --wb-text__line-height: var(--wb-ds-line-height--s);
            --wb-text__color: var(--wb-ds-color-gray--80);
        }
    }
}

/** ICONS */
:root {
    --wb-ds-icon-size--2xs: var(--wb-ds-size--50);
    --wb-ds-icon-size--xs: var(--wb-ds-size--75);
    --wb-ds-icon-size--s: var(--wb-ds-size--88);
    --wb-ds-icon-size--m: var(--wb-ds-size--100);
    --wb-ds-icon-size--l: var(--wb-ds-size--150);
    --wb-ds-icon-size--xl: var(--wb-ds-size--300);
    --wb-ds-icon-size--2xl: var(--wb-ds-size--500);
}

/** TOOLTIPS */
:root {
    --wb-tooltip__background: var(--wb-ds-color-gray--white);
}

/* BUTTON */
:root {
    --wb-button__bezel-top: var(--wb-ds-size--100);
    --wb-button__bezel-bottom: var(--wb-ds-size--100);
    --wb-button__bezel-left: var(--wb-ds-size--200);
    --wb-button__bezel-right: var(--wb-ds-size--200);
    --wb-button__border-radius: var(--wb-ds-border-radius--medium);
}

/* COLLAPSIBLE */
:root {
    --wb-collapsible__bezel: 0;
    --wb-collapsible-content__bezel-right: 0;
    --wb-collapsible-content__bezel-left: 0;
}

// TODO WEBBLQS-840 use new tokens once ready
wb-collapsible {
    .wb-collapsible__header:focus-visible {
        outline: var(--wb-ds-color-primary--default) solid var(--wb-ds-border-width--thick);
    }
}

/* RICH-TEXT */
:root {
    --wb-html-a__color: var(--wb-ds-color-primary--120);
    --wb-html-a__color--hover: var(--wb-ds-color-primary--100);
    --wb-richtext-a__color: var(--wb-html-a__color);
    --wb-richtext-a__color--hover: var(--wb-html-a__color--hover);
}

wb-html,
wb-richtext {
    // links in rich-text
    a {
        --wb-html-a__text-decoration: underline;
        --wb-html-a__transition: color var(--wb-ds-animation-delay--slow) var(--wb-ds-animation-timing-function),
            box-shadow var(--wb-ds-animation-delay--slow) var(--wb-ds-animation-timing-function),
            background var(--wb-ds-animation-delay--slow) var(--wb-ds-animation-timing-function),
            opacity var(--wb-ds-animation-delay--slow) var(--wb-ds-animation-timing-function);
        --wb-richtext-a__text-decoration: var(--wb-html-a__text-decoration);
        --wb-richtext-a__transition: var(--wb-html-a__transition);

        display: inline;
        text-underline-offset: var(--wb-ds-size--13);

        &:hover,
        &:focus,
        &:active {
            --wb-html-a__color: var(--wb-html-a__color--hover);
            --wb-richtext-a__color: var(--wb-html-a__color);
        }

        &:focus-visible {
            --wb-link__outline: var(--wb-ds-border-width--thick) solid var(--wb-ds-color-primary--100);

            outline-offset: var(--wb-ds-border-width--thick);
        }
    }

    // lists in rich-text
    ul {
        list-style-type: "—";
    }

    li {
        padding-left: var(--wb-ds-space--xs);
    }

    h1 + p,
    h2 + p,
    h3 + p,
    h4 + p {
        --wb-html-paragraph__bezel-top: 0;
        --wb-richtext-paragraph__bezel-top: var(--wb-html-paragraph__bezel-top);
    }

    p + h1 {
        --wb-html-title-level-1__bezel-top: calc(var(--wb-ds-space--s) + var(--wb-ds-space--l));
        --wb-richtext-title-level-1__bezel-top: var(--wb-html-title-level-1__bezel-top);
    }

    p + h2 {
        --wb-html-title-level-2__bezel-top: calc(var(--wb-ds-space--s) + var(--wb-ds-space--l));
        --wb-richtext-title-level-2__bezel-top: var(--wb-html-title-level-2__bezel-top);
    }

    p + h3 {
        --wb-html-title-level-3__bezel-top: calc(var(--wb-ds-space--s) + var(--wb-ds-space--l));
        --wb-richtext-title-level-3__bezel-top: var(--wb-html-title-level-3__bezel-top);
    }

    p + h4 {
        --wb-html-title-level-4__bezel-top: calc(var(--wb-ds-space--xs) + var(--wb-ds-space--l));
        --wb-richtext-title-level-4__bezel-top: var(--wb-html-title-level-4__bezel-top);
    }
}

/** TYPOGRAPHIES */
:root {
    @include typography.create(
        small,
        (
            "font-size": var(--wb-ds-font-size--s),
            "line-height": var(--wb-ds-line-height--s),
        )
    );
    @include typography.create(
        tiny,
        (
            "font-size": var(--wb-ds-font-size--xs),
            "line-height": var(--wb-ds-line-height--xs),
            "font-weight": var(--wb-ds-font-weight--default),
        )
    );
    @include typography.create(
        lead,
        (
            "font-size": var(--wb-ds-font-size--xl),
            "line-height": var(--wb-ds-line-height--xl),
        )
    );
}

/** LINKS */

// NOTE: this lint rule is wrong in this context, all kinds have the same specificity
// stylelint-disable no-descending-specificity
wb-link,
wb-button[link] {
    --wb-link__box-shadow: inset 0 0 0 0 transparent;
    --wb-link__transition: color var(--wb-ds-animation-delay--slow) ease-in-out, box-shadow var(--wb-ds-animation-delay--slow) ease-in-out,
        background var(--wb-ds-animation-delay--slow) ease-in-out, opacity var(--wb-ds-animation-delay--slow) ease-in-out;

    .wb-link,
    .wb-button {
        &:focus-visible {
            --wb-link__outline: var(--wb-ds-border-width--thick) solid var(--wb-ds-color-primary--100);

            outline-offset: var(--wb-ds-border-width--thick);
        }
    }

    &[kind="contrast"] {
        .wb-link,
        .wb-button {
            --wb-link__color: var(--wb-ds-color-gray--white);

            &:hover,
            &:active,
            &:focus {
                --wb-link__color: var(--wb-ds-color-gray--20);
            }

            &:focus-visible {
                --wb-link__outline: var(--wb-ds-border-width--thick) solid var(--wb-ds-color-gray--white);
            }
        }
    }

    &[kind="default"] {
        .wb-link,
        .wb-button {
            --wb-link__color: var(--wb-ds-color-primary--100);

            &:hover,
            &:active,
            &:focus {
                --wb-link__color: var(--wb-ds-color-primary--120);
            }
        }
    }

    &[kind="dark"] {
        .wb-link,
        .wb-button {
            --wb-link__color: var(--wb-ds-color-primary--140);

            &:hover,
            &:active,
            &:focus {
                --wb-link__color: var(--wb-ds-color-primary--140);
            }
        }
    }

    &[kind="inactive"] {
        .wb-link,
        .wb-button {
            --wb-link__color: var(--wb-ds-color-gray--80);
        }
    }

    &[kind="tile"] {
        .wb-link,
        .wb-button {
            --wb-link__background: var(--wb-ds-color-primary--10);
            --wb-link__color: var(--wb-ds-color-primary--140);

            &:hover,
            &:active,
            &:focus {
                --wb-link__background: var(--wb-ds-color-primary--140);
                --wb-link__color: var(--wb-ds-color-gray--white);
            }
        }
    }

    &[kind="tile-contrast"] {
        .wb-link,
        .wb-button {
            --wb-link__background: var(--wb-ds-color-gray--white);
            --wb-link__color: var(--wb-ds-color-primary--140);

            &:hover,
            &:active,
            &:focus {
                --wb-link__background: var(--wb-ds-color-primary--140);
                --wb-link__color: var(--wb-ds-color-gray--white);
            }
        }
    }
}

/** BUTTONS */
wb-button,
wb-link[button] {
    --wb-button__font-size: var(--wb-ds-font-size-static--m);
    --wb-button__line-height: var(--wb-ds-line-height-static--m);
    --wb-button__font-weight: var(--wb-ds-font-weight--bold);

    .wb-button,
    .wb-link {
        &:focus-visible {
            --wb-button__outline: var(--wb-ds-border-width--thick) solid var(--wb-ds-color-primary--100);

            outline-offset: var(--wb-ds-border-width--thick);
        }
    }

    &[kind="primary-inverted"],
    &[kind="secondary-inverted"],
    &[kind="tertiary-inverted"],
    &[kind="icon-inverted"] {
        .wb-button,
        .wb-link {
            &:focus-visible {
                --wb-button__outline: var(--wb-ds-border-width--thick) solid var(--wb-ds-color-gray--white);
            }
        }
    }

    &[kind="primary"] {
        .wb-button,
        .wb-link {
            --wb-button__border-color: transparent;
            --wb-button__background: var(--wb-ds-color-primary--100);
            --wb-button__color: var(--wb-ds-color-gray--white);

            &:active,
            &:focus {
                --wb-button__background: var(--wb-ds-color-primary--140);
            }

            // hover styles must come after active/focus
            &:hover {
                --wb-button__background: var(--wb-ds-color-primary--120);
            }

            &:disabled,
            &.wb-link--disabled {
                --wb-button__background: var(--wb-ds-color-gray--20);
                --wb-button__color: var(--wb-ds-color-gray--120);
            }
        }
    }

    &[kind="primary-inverted"],
    &[kind="light"] {
        .wb-button,
        .wb-link {
            --wb-button__border-color: transparent;
            --wb-button__background: var(--wb-ds-color-gray--white);
            --wb-button__color: var(--wb-ds-color-primary--100);

            &:active,
            &:focus {
                --wb-button__background: var(--wb-ds-color-gray--40);
                --wb-button__color: var(--wb-ds-color-primary--120);
            }

            // hover styles must come after active/focus
            &:hover {
                --wb-button__background: var(--wb-ds-color-gray--20);
                --wb-button__color: var(--wb-ds-color-primary--120);
            }

            &:disabled,
            &.wb-link--disabled {
                --wb-button__background: var(--wb-ds-color-white--10);
                --wb-button__color: var(--wb-ds-color-white--80);
            }
        }
    }

    &[kind="default"],
    &[kind="secondary"],
    &[kind="outline"] {
        .wb-button,
        .wb-link {
            --wb-button__border-width: var(--wb-ds-border-width--thick);
            --wb-button__border-color: var(--wb-ds-color-primary--100);
            --wb-button__color: var(--wb-ds-color-primary--100);

            &:active,
            &:focus {
                --wb-button__border-color: var(--wb-ds-color-primary--140);
                --wb-button__color: var(--wb-ds-color-primary--140);
            }

            // hover styles must come after active/focus
            &:hover {
                --wb-button__border-color: var(--wb-ds-color-primary--120);
                --wb-button__color: var(--wb-ds-color-primary--120);
            }

            &:disabled,
            &.wb-link--disabled {
                --wb-button__border-color: var(--wb-ds-color-gray--80);
                --wb-button__color: var(--wb-ds-color-gray--100);
            }
        }
    }

    &[kind="secondary-inverted"] {
        .wb-button,
        .wb-link {
            --wb-button__border-width: var(--wb-ds-border-width--thick);
            --wb-button__border-color: var(--wb-ds-color-gray--white);
            --wb-button__color: var(--wb-ds-color-gray--white);

            &:active,
            &:focus {
                --wb-button__border-color: var(--wb-ds-color-gray--60);
                --wb-button__color: var(--wb-ds-color-gray--60);
            }

            // hover styles must come after active/focus
            &:hover {
                --wb-button__border-color: var(--wb-ds-color-gray--20);
                --wb-button__color: var(--wb-ds-color-gray--20);
            }

            &:disabled,
            &.wb-link--disabled {
                --wb-button__border-color: var(--wb-ds-color-white--30);
                --wb-button__background: var(--wb-ds-color-white--10);
                --wb-button__color: var(--wb-ds-color-white--80);
            }
        }
    }

    &[kind="tertiary"],
    &[kind="icon"],
    &[kind="unstyled"] {
        .wb-button,
        .wb-link {
            --wb-button__border-color: transparent;
            --wb-button__background: none;
            --wb-button__color: var(--wb-ds-color-primary--100);

            &:active,
            &:focus {
                --wb-button__color: var(--wb-ds-color-primary--140);
            }

            // hover styles must come after active/focus
            &:hover {
                --wb-button__color: var(--wb-ds-color-primary--120);
            }

            &:disabled,
            &.wb-link--disabled {
                --wb-button__color: var(--wb-ds-color-gray--80);
            }
        }
    }

    &[kind="tertiary-inverted"],
    &[kind="icon-inverted"],
    &[kind="unstyled-inverted"] {
        .wb-button,
        .wb-link {
            --wb-button__border-color: transparent;
            --wb-button__background: none;
            --wb-button__color: var(--wb-ds-color-gray--white);

            &:active,
            &:focus {
                --wb-button__color: var(--wb-ds-color-gray--60);
            }

            // hover styles must come after active/focus
            &:hover {
                --wb-button__color: var(--wb-ds-color-gray--20);
            }

            &:disabled,
            &.wb-link--disabled {
                --wb-button__color: var(--wb-ds-color-gray--60);
            }
        }
    }

    &[kind="icon"],
    &[kind="icon-inverted"] {
        .wb-button,
        .wb-link {
            --wb-button__bezel-left: var(--wb-ds-space--s);
            --wb-button__bezel-right: var(--wb-ds-space--s);
        }
    }

    &[kind="unstyled"] {
        .wb-button,
        .wb-link {
            --wb-button__bezel-top: 0;
            --wb-button__bezel-bottom: 0;
            --wb-button__bezel-left: 0;
            --wb-button__bezel-right: 0;
        }
    }
}

wb-toggle {
    --wb-ds-field__height: 2.5rem;
    --wb-ds-field__width: 5rem;

    --wb-icon__size: var(--wb-toggle__height);
    padding-top: 5px;

    .wb-field-label {
        cursor: var(--wb-ds-field__cursor--valid, pointer);
    }

    &[disabled] {
        opacity: 1;

        .wb-field-label--disabled {
            cursor: var(--wb-ds-field__cursor--disabled, not-allowed);
        }
    }

    .wb-toggle__input {
        background: var(--wb-ds-color-gray--40);

        &:after {
            border-color: var(--wb-ds-color-gray--40);
        }

        &:disabled {
            background: var(--wb-ds-color-gray--40);

            &:after {
                border-color: var(--wb-ds-color-gray--40);
            }

            &:checked {
                background: #65a883;

                &:after {
                    border-color: #65a883;
                }
            }
        }

        &:focus {
            &:after {
                border-color: transparent;
                outline-color: var(--wb-ds-color-primary--100);
            }
        }

        &:hover {
            background: var(--wb-ds-color-gray--80);

            &:after {
                border-color: var(--wb-ds-color-gray--80);
            }
        }

        &:checked {
            background: var(--wb-ds-color-success--100);

            &:after {
                border-color: var(--wb-ds-color-success--100);
            }

            &:focus {
                &:after {
                    border-color: transparent;
                }
            }

            &:hover {
                background: var(--wb-ds-color-success--100);

                &:after {
                    border-color: var(--wb-ds-color-success--120);
                }
            }
        }
    }
}
// stylelint-enable no-descending-specificity

/* RESPONSIVE DISPLAY (base) */
:root {
    --ewz-show-in--xs: none;
    --ewz-show-in--sm: none;
    --ewz-show-in--md: none;
    --ewz-show-in--lg: none;
    --ewz-show-in--xl: none;
    --ewz-show-in--xxl: none;
}

/* RESPONSIVE DISPLAY (per viewport) */
@if true {
    @include media.at-least(xs) {
        :root {
            --ewz-show-in--xs: block;
            --ewz-hide-in--xs: none;
        }
    }

    @include media.at-least(sm) {
        :root {
            --ewz-show-in--sm: block;
            --ewz-hide-in--sm: none;

            --wb-ds-grid__grid-margin: calc(var(--wb-ds-space--m) * 2);
        }
    }

    @include media.at-least(md) {
        :root {
            --ewz-show-in--md: block;
            --ewz-hide-in--md: none;
        }
    }

    @include media.at-least(lg) {
        :root {
            /* RESPONSIVE DISPLAY */
            --ewz-show-in--lg: block;
            --ewz-hide-in--lg: none;

            --wb-ds-grid__grid-margin: calc(var(--wb-ds-space--m) * 2.5);
        }
    }

    @include media.at-least(xl) {
        :root {
            /* RESPONSIVE DISPLAY */
            --ewz-show-in--xl: block;
            --ewz-hide-in--xl: none;

            /** GRID */
            --wb-ds-grid__max-width: 1280px;
        }
    }

    @include media.at-least(xxl) {
        :root {
            /* RESPONSIVE DISPLAY */
            --ewz-show-in--xxl: block;
            --ewz-hide-in--xxl: none;
        }
    }
}

/* Remove the top spacing of the second page section if two consecutive page sections with the same background color are rendered one after the other */

/* lightblue variant */
ewz-pagesection[bgcolor="bg-bleed--blue-bright"] + ewz-pagesection[bgcolor="bg-bleed--blue-bright"],
/* darkblue variant */
ewz-pagesection[bgcolor="theme--gradient-blue"] + ewz-pagesection[bgcolor="theme--gradient-blue"],
/* no background / white variant */
ewz-pagesection:not([bgcolor]) + ewz-pagesection:not([bgcolor]),
ewz-pagesection:not([bgcolor]) + ewz-pagesection[bgcolor="white"],
ewz-pagesection[bgcolor="white"] + ewz-pagesection[bgcolor="white"],
ewz-pagesection[bgcolor="white"] + ewz-pagesection:not([bgcolor]) {
    --ewz-pagesection--bezel-top: 0;
}
